
@import 'tailwindcss/base';
/* @import './docsearch.css'; */
/* @import 'global.css'; */
/* @import './post-search.css'; */

/* @import './prism.css'; */

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
